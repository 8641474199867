//------------------------------------------------------------------------------ VUE

import { createApp } from 'vue';

//------------------------------------------------------------------------------ STYLE

import './assets/styles/main.scss';

//------------------------------------------------------------------------------ ROUTER

import { setupRouter } from './router';

const router = setupRouter();

//------------------------------------------------------------------------------ UTILS

import './utils/LodashMixin';

//------------------------------------------------------------------------------ COMPONENTS & DIRECTIVE

import { 
    LsnCenterCenterLayout,
    LsnTopBar,
    LsnClickOutside,
    LsnRadioBox,
    LsnIcon
} from 'lausanne';

//------------------------------------------------------------------------------ APP AND STORE

import App from './App';

//------------------------------------------------------------------------------ APP BUILDING

const app = createApp(App)
    .use(router)
    .directive('lsn-click-outside', LsnClickOutside)
    .component('LsnCenterCenterLayout', LsnCenterCenterLayout)
    .component('LsnRadioBox', LsnRadioBox)
    .component('LsnTopBar', LsnTopBar)
    .component('LsnIcon', LsnIcon);

router.isReady().then(() =>
{
    app.mount('#app');
});