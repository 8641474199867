<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>

    <div class="question__content">
        {{ question.content }}
    </div>

    <textarea v-model="text" class="question__textarea"></textarea>

    <div class="question__navigation">

        <div class="question__navigation-item" @click="$emit('previous')" @touch="$emit('previous')">
            <lsn-icon iname="lsn-chevron-left" />
        </div>

        <div class="question__navigation-item" @click="next()" @touch="next()">
            <lsn-icon iname="lsn-chevron-right" />
        </div>

    </div>
    
</template>

<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>

// import * as _ from 'lodash';
import axios from '@/axios';
import Cookie from '@/utils/Cookie';

export default
{
    name: 'TextQuestion',

    props:
    {
        question:
        {
            type:     Object,
            required: true
        }
    },

    emits: ['previous', 'next'],

    data()
    {
        return {
            text: null
        };
    },

    watch:
    {
        'question.value':
        {
            immediate: true,
            handler:   function(newVal)
            {
                this.text = newVal;
            }
        }
    },

    methods:
    {
        next()
        {
            let data = {
                FK_S_QuestionId: this.question.id,
                'value':         this.text,
                survey_hid:      this.$route.params.survey_hid,
                aid:             Cookie.getItem('aid')
            };

            let url = ('/api/answer/:survey_hid').replace(':survey_hid', this.$route.params.survey_hid);

            axios.post(url, data).then((response) =>
            {
                this.$emit('next', response.data['value']);
            });
        }
    }
};

</script>

<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>

.question__content
{
    @apply text-primary-500 text-center leading-5;
}

.question__textarea
{
    min-height: 200px;
    @apply mt-8 flex items-center justify-center w-full text-primary-400 border-2 border-gray-300 px-2 py-2 mb-4 rounded-md outline-none leading-normal;
}

.question__textarea:focus
{
    @apply border-primary-300;
}

.question__navigation
{
    @apply flex items-center justify-around px-4 mt-9;
}

.question__navigation-item
{
    @apply flex items-center justify-center text-primary-500 border-2 border-primary-400 rounded-full;
}

.question__navigation-item--disabled
{
    @apply text-gray-400 border-gray-400;
}

.question__navigation-item svg
{
    height: 60px;
    width: 60px;
}

</style>