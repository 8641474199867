<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>

    <lsn-center-center-layout footer-sticky>

        <template #lsn-top-bar>
            <lsn-top-bar>
                <template #lsn-top-bar-center>
                    <img class="logo" src="/img/ismat_logo.png" />
                </template>
            </lsn-top-bar>
        </template>

        <template #default>

            <div v-if="question" class="page-layout">
                <component :is="getQuestionComponent(question)" :question="question" @previous="previous" @next="next"></component>
            </div>

        </template>

        <template #lsn-footer>
            <div class="flex items-center justify-center h-40px px-4">
                <div class="text-xs text-primary-300 font-thin">
                    &copy; {{ year }} - Ismat Group SA - Tous droits réservés
                </div>
                <div class="ml-auto text-xs text-primary-300 font-thin">
                    v.{{ version }}
                </div>
            </div>
        </template>

    </lsn-center-center-layout>
    
</template>

<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>

import * as _ from 'lodash';
import Cookie from '@/utils/Cookie';
import axios from '@/axios';
import RadioQuestion from './RadioQuestion';
import TextQuestion from './TextQuestion';

export default
{
    name: 'Welcome',

    data()
    {
        return {
            loading:   true,
            questions: [],
            aid:       null, // anonymous ID
            year:      new Date().getFullYear(),
            version:   process.env.VUE_APP_VERSION
        };
    },

    computed:
    {
        question()
        {
            let id = parseInt(this.$route.params.question_id);

            if(!id)
            {
                return null;
            }

            return _.find(this.questions, ['id', id]);
        }
    },

    created()
    {
        this.loading = true;

        let requests = [
            this.setAnonymousID(),
            this.fetchQuestions()
        ];

        Promise.all(requests).then(() =>
        {
            // Select first question if not question ID is given in URL.
            if(!this.$route.params.question_id)
            {
                this.$router.push({params: { question_id: _.head(this.questions).id }});
            }

            this.loading = false;
        });
    },

    methods:
    {
        fetchQuestions()
        {
            let url = '/api/question/2/:survey_hid/:aid';
            url = url.replace(':survey_hid', this.$route.params.survey_hid);
            url = url.replace(':aid', this.aid);

            return axios.get(url).then((response) =>
            {
                this.questions = response.data;

                return response;
            });
        },

        setAnonymousID()
        {
            let aid = Cookie.getItem('aid');

            if(!aid)
            {
                aid = _.random(1000000, 9999999);
                Cookie.setItem('aid', aid, 'today');
            }

            this.aid = aid;

            return aid;
        },

        getQuestionComponent(question)
        {
            switch(question.modeView)
            {
                case 'radio':
                    return RadioQuestion;

                case 'text':
                    return TextQuestion;
            }
        },

        previous()
        {
            this.$router.go(-1);
        },

        next(value)
        {
            let question_id = parseInt(this.$route.params.question_id);

            let question = _.find(this.questions, ['id', question_id]);
            question.value = value;

            let index = _.findIndex(this.questions, ['id', question_id]);
            let nextIndex = index + 1;

            if(this.questions[nextIndex])
            {
                let nextQuestion = this.questions[nextIndex];
                this.$router.push({params: { question_id: nextQuestion.id }});
            }
            else
            {
                this.$router.push({
                    name:   'conclusion',
                    params:
                    {
                        survey_hid: this.$route.params.survey_hid
                    }
                });
            }
        }
    }
};

</script>

<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>

.logo
{
    @apply inline w-20;
}

.page-layout
{
    max-width: 400px;
    @apply mx-auto px-8; 
}

</style>