import axios from 'axios';
import _ from 'lodash';

//------------------------------------------------------------------------------ CONFIGURATIONS

axios.defaults.baseURL = process.env.VUE_APP_API_LOCATION;

//------------------------------------------------------------------------------ INTERCEPTOR

axios.interceptors.response.use(
    function(response)
    {
        if(_.has(response, 'data.data'))
        {
            response.meta = response.data.meta;
            response.data = response.data.data;
        }

        return response;
    },
    function(error)
    {
        if(_.has(error, 'response.data'))
        {
            error.response.errors = error.response.data.errors;
            delete error.response.data;
        }

        return Promise.reject(error);
    }
);

//------------------------------------------------------------------------------

export default axios;

