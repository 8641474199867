<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>

    <lsn-center-center-layout footer-sticky>

        <template #default>

            <div class="page-layout">

                <!-- title -->
                <div class="text-center">
                    <img class="logo" src="/img/ismat_logo.png" />
                    <div class="mt-2 text-primary-400 text-center text-2xl font-light">
                        Satisfaction
                    </div>
                </div>

                <!-- patient number field -->
                <div class="mt-14 flex flex-wrap justify-center">
                    <label class="w-full mb-2 text-center text-gray-500">Code de l'enquête</label>
                    <input v-model="survey_hid" type="text" class="field__survey-code" placeholder="8 caractères" @blur="is_pristine = false" @keyup="onSurveyCodeChange()" />
                </div>
        
                <!-- error display -->
                <div class="flex items-center justify-center h-10 mt-2 mb-8">
                    <div v-if="!is_pristine && error" class="text-sm text-red-500">
                        {{ error }}
                    </div>
                </div>

                <!-- button -->
                <div class="flex flex-wrap justify-center">
                    <div v-if="startable" class="lsn-btn lsn-btn--primary h-auto" @click="startSurvey()" @touchstart="startSurvey()">
                        démarrer l'enquête
                    </div>
                    <div v-else class="lsn-btn lsn-btn--gray-outline h-auto">
                        démarrer l'enquête
                    </div>
                </div>

            </div>

        </template>

        <template #lsn-footer>
            <div class="flex items-center justify-center h-40px px-4">
                <div class="text-xs text-primary-300 font-thin">
                    &copy; {{ year }} - Ismat Group SA - Tous droits réservés
                </div>
                <div class="ml-auto text-xs text-primary-300 font-thin">
                    v.{{ version }}
                </div>
            </div>
        </template>

    </lsn-center-center-layout>
    
</template>

<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>

import axios from '@/axios';

export default
{
    name: 'Welcome',

    data()
    {
        return {
            survey_hid:  null,
            is_pristine: true,
            startable:   false,
            error:       null,
            year:        new Date().getFullYear(),
            version:     process.env.VUE_APP_VERSION
        };
    },

    created()
    {
        if(this.$route.params.survey_hid)
        {
            this.survey_hid = this.$route.params.survey_hid;
            this.validateSurveyCode();
        }
    },

    methods:
    {
        onSurveyCodeChange()
        {
            this.validateSurveyCode();
        },

        validateSurveyCode()
        {
            if(this.survey_hid)
            {
                let url = ('/api/survey/:survey_hid').replace(':survey_hid', this.survey_hid);

                axios.get(url).then(() =>
                {
                    this.error = null;
                    this.startable = true;
                }).catch((error) =>
                {
                    this.startable = false;
                    if(error.response.status === 404)
                    {
                        this.error = 'Ce code ne correspond à aucune enquête';
                    }
                    else
                    {
                        this.error = "Une erreur inattendue s'est produite";
                    }
                });
            }
            else
            {
                this.error = 'Le code est obligatoire';
            }
        },

        startSurvey()
        {
            this.$router.push({name:   'questionnaire', params: {
                survey_hid: this.survey_hid
            }});
        }
    }
};

</script>

<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>

.page-layout
{
    max-width: 400px;
    @apply mx-auto px-4; 
}

.logo
{
    @apply inline w-32;
}

.field__survey-code
{
    height: 34px;
    letter-spacing: 0.3em;
    @apply w-48 px-2 text-primary-500 text-center text-xl border border-gray-300 appearance-none rounded-none focus:outline-none focus:border-primary-500;
}

.field__survey-code::placeholder
{
    letter-spacing: 0.1em;
    @apply text-center text-base text-gray-300;
}

</style>