/**********
 * Routes *
 *********/

import Welcome from '@/views/Welcome';
import Qr from '@/views/Qr';
import Total from '@/views/Total';
import Conclusion from '@/views/Conclusion';
import Questionnaire from '@/views/questionnaire';

export default[

    {
        path:      '/accueil/:survey_hid?',
        alias:     '/welcome/:survey_hid?',
        name:      'welcome',
        component: Welcome
    },

    {
        path:      '/qr/:survey_hid',
        name:      'qr',
        component: Qr
    },

    {
        path:      '/total/:survey_hid',
        name:      'total',
        component: Total
    },

    {
        path:      '/questionnaire/:survey_hid/:question_id?',
        name:      'questionnaire',
        component: Questionnaire
    },

    {
        path:      '/conclusion/:survey_hid',
        name:      'conclusion',
        component: Conclusion
    },

    {
        path:     '/',
        redirect: { name: 'welcome' }
    },

    {
        path:     '/*',
        redirect: { name: 'welcome' }
    }
];
