/**********
 * Router *
 *********/

import { createWebHistory, createRouter } from 'vue-router';
import routes from './routes';

export function setupRouter()
{
    //-------------------------------------------------------------------------- CONSTRUCTOR

    const router = createRouter({
        history: createWebHistory(),
        base:    process.env.VUE_APP_API_LOCATION,
        routes
    });

    //--------------------------------------------------------------------------

    return router;
}