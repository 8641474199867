<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>
    <router-view v-slot="{ Component }">
        <transition name="fade" appear>
            <component :is="Component"></component>
        </transition>
    </router-view>
</template>

<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>

export default
{

};

</script>

<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>



</style>