<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>

    <div class="question__content">
        {{ question.content }}
    </div>

    <div class="question__radio">
        <div v-for="answer in answers" 
             :key="answer" 
             class="question__radio-item" 
             :class="{
                 'question__radio-item--selected': answer == selectedAnswer
             }"
             @click="selectAnswer(answer)"
        >

            <template v-if="answer == '1'">
                <lsn-icon iname="lsn-emoticon-sad-outline-alt" />
                <lsn-icon iname="lsn-emoticon-sad-outline-alt" />
            </template>

            <template v-else-if="answer == '2'">
                <lsn-icon iname="lsn-emoticon-sad-outline-alt" />
            </template>

            <template v-else-if="answer == '3'">
                <lsn-icon iname="lsn-emoticon-happy-outline-alt" />
            </template>

            <template v-else-if="answer == '4'">
                <lsn-icon iname="lsn-emoticon-happy-outline-alt" />
                <lsn-icon iname="lsn-emoticon-happy-outline-alt" />
            </template>

            <template v-else>
                {{ answer }}
            </template>

        </div>
    </div>

    <div class="question__navigation">

        <div class="question__navigation-item" @click="$emit('previous')" @touch="$emit('previous')">
            <lsn-icon iname="lsn-chevron-left" />
        </div>

        <div v-if="selectedAnswer" class="question__navigation-item" @click="next()" @touch="next()">
            <lsn-icon iname="lsn-chevron-right" />
        </div>

        <div v-else class="question__navigation-item question__navigation-item--disabled">
            <lsn-icon iname="lsn-chevron-right" />
        </div>

    </div>
    
</template>

<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>

import axios from '@/axios';
import Cookie from '@/utils/Cookie';

export default
{
    name: 'RadioQuestion',

    props:
    {
        question:
        {
            type:     Object,
            required: true
        }
    },

    emits: ['previous', 'next'],

    data()
    {
        return {
            selectedAnswer: null
        };
    },

    computed:
    {
        answers()
        {
            let answers = this.question.possibleAnswer.split(';');

            return answers.reverse();
        }
    },

    watch:
    {
        'question.value':
        {
            immediate: true,
            handler:   function(newVal)
            {
                this.selectedAnswer = newVal;
            }
        }
    },

    methods:
    {
        selectAnswer(answer)
        {
            this.selectedAnswer = answer;
        },

        next()
        {
            let data = {
                FK_S_QuestionId: this.question.id,
                'value':         this.selectedAnswer,
                survey_hid:      this.$route.params.survey_hid,
                aid:             Cookie.getItem('aid')
            };

            let url = ('/api/answer/:survey_hid').replace(':survey_hid', this.$route.params.survey_hid);

            axios.post(url, data).then((response) =>
            {
                this.$emit('next', response.data['value']);
            });
        }
    }
};

</script>

<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>

.question__content
{
    @apply text-primary-500 text-center leading-5;
}

.question__radio
{
    @apply mt-8;
}

.question__radio-item
{
    min-height: 46px;
    @apply flex items-center justify-center text-primary-400 border border-primary-300 px-3 py-1 mb-4 rounded-md;
}

.question__radio-item--selected
{
    @apply bg-primary-300 text-white;
}

.question__radio-item svg
{
    height: 38px;
    width: 38px;
}

.question__navigation
{
    @apply flex items-center justify-around px-4 mt-9;
}

.question__navigation-item
{
    @apply flex items-center justify-center text-primary-500 border-2 border-primary-400 rounded-full;
}

.question__navigation-item--disabled
{
    @apply text-gray-400 border-gray-400;
}

.question__navigation-item svg
{
    height: 60px;
    width: 60px;
}

</style>