<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>

    <lsn-center-center-layout footer-sticky>

        <template #default>

            <div class="page-layout">

                <!-- title -->
                <div class="text-center">
                    <img class="logo" src="/img/ismat_logo.png" />
                    <div class="mt-2 text-primary-400 text-center text-2xl font-light">
                        Satisfaction
                    </div>
                </div>

                <div class="qr-wrapper">
                    <qrcode-vue :value="url" size="300"></qrcode-vue>
                </div>

            </div>

        </template>

        <template #lsn-footer>
            <div class="flex items-center justify-center h-40px px-4">
                <div class="text-xs text-primary-300 font-thin">
                    &copy; {{ year }} - Ismat Group SA - Tous droits réservés
                </div>
                <div class="ml-auto text-xs text-primary-300 font-thin">
                    v.{{ version }}
                </div>
            </div>
        </template>

    </lsn-center-center-layout>
    
</template>

<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>

import QrcodeVue from 'qrcode.vue';

export default
{
    name: 'Qr',

    components:
    {
        QrcodeVue
    },

    data()
    {
        return {
            year:    new Date().getFullYear(),
            version: process.env.VUE_APP_VERSION
        };
    },

    computed:
    {
        url()
        {
            return process.env.VUE_APP_API_LOCATION + '/welcome/' + this.$route.params.survey_hid; 
        }
    }
};

</script>

<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>

.page-layout
{
    max-width: 400px;
    @apply mx-auto px-4; 
}

.logo
{
    @apply inline w-32;
}

.qr-wrapper
{
    @apply mt-8 flex justify-center;
}

</style>