<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>

    <lsn-center-center-layout footer-sticky>

        <template #default>

            <div class="page-layout">

                <!-- title -->
                <div class="text-center">
                    <img class="logo" src="/img/ismat_logo.png" />
                    <div class="mt-2 text-primary-400 text-center text-2xl font-light">
                        Satisfaction
                    </div>
                </div>

                <!-- thank you -->
                <h1 class="mt-16 mb-24 mx-auto text-center text-primary-500 text-xl">
                    Merci pour votre feed-back !
                </h1>

                <!-- button -->
                <div class="flex flex-wrap justify-center">
                    <a href="https://ismat.com" class="lsn-btn lsn-btn--primary">
                        ismat.com
                    </a>
                </div>

            </div>

        </template>

        <template #lsn-footer>
            <div class="flex items-center justify-center h-40px px-4">
                <div class="text-xs text-primary-300 font-thin">
                    &copy; {{ year }} - Ismat Group SA - Tous droits réservés
                </div>
                <div class="ml-auto text-xs text-primary-300 font-thin">
                    v.{{ version }}
                </div>
            </div>
        </template>

    </lsn-center-center-layout>
    
</template>

<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>

export default
{
    name: 'Conclusion',

    data()
    {
        return {
            year:    new Date().getFullYear(),
            version: process.env.VUE_APP_VERSION
        };
    }
};

</script>

<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>

.page-layout
{
    max-width: 400px;
    @apply mx-auto px-4; 
}

.logo
{
    @apply inline w-32;
}

</style>