<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>

    <lsn-center-center-layout footer-sticky>

        <template #default>

            <div class="page-layout">

                <!-- title -->
                <div class="text-center">
                    <img class="logo" src="/img/ismat_logo.png" />
                    <div class="mt-2 text-primary-400 text-center text-2xl font-light">
                        Satisfaction
                    </div>
                </div>

                <div class="my-12 text-center text-primary-500 text-9xl">
                    {{ total }}
                </div>

                <div class="mt-12 text-center text-gray-500 text-sm">
                    refraichissement dans: {{ seconds }} secondes
                </div>

            </div>

        </template>

        <template #lsn-footer>
            <div class="flex items-center justify-center h-40px px-4">
                <div class="text-xs text-primary-300 font-thin">
                    &copy; {{ year }} - Ismat Group SA - Tous droits réservés
                </div>
                <div class="ml-auto text-xs text-primary-300 font-thin">
                    v.{{ version }}
                </div>
            </div>
        </template>

    </lsn-center-center-layout>
    
</template>

<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>

import axios from '@/axios';

export default
{
    name: 'Total',

    data()
    {
        return {
            total:   '-',
            seconds: 5,
            year:    new Date().getFullYear(),
            version: process.env.VUE_APP_VERSION
        };
    },

    created()
    {
        this.updateTotal();
        this.refresh();
    },

    methods:
    {
        updateTotal()
        {
            let url = '/api/survey/total/:survey_hid';
            url = url.replace(':survey_hid', this.$route.params.survey_hid);
            
            axios.get(url).then((response) =>
            {
                this.total = response.data;
                this.seconds = 10;
            });
        },

        refresh()
        {
            setInterval(() =>
            {
                if(this.seconds == 0)
                {
                    this.updateTotal();
                }
                else
                {
                    this.seconds -= 1;
                }
            }, 1000); 
        }
    }
};

</script>

<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>

.page-layout
{
    max-width: 400px;
    @apply mx-auto px-4; 
}

.logo
{
    @apply inline w-32;
}

.qr-wrapper
{
    @apply mt-8 flex justify-center;
}

</style>